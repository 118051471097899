import FqApi from '@/services/fq-api'

export default {
  async getDevices(companyId) {
    const url = `/clienttools/devices/${companyId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async saveDevice(entity) {
    const url = `/clienttools/${entity.id}`
    const result = await FqApi.put(url, entity)
    return result.data
  },
  async insertDevice(entity) {
    const url = '/clienttools/'
    const result = await FqApi.post(url, entity)
    return result
  }
}
