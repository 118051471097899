<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="false"
      :show-new="canManage"
      :show-print="false"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <!-- <div class="columns is-gapless">
      <div class="column"> -->
    <div class="tile is-ancestor is-parent">
      <div v-if="activeDevices.length === 0 && !isLoading"
        class="tile is-parent">
        <div class="tile is-vertical is-12">
          <div class="box">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>No devices registered</p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div v-else
        class="tile is-parent">
        <div class="columns is-multiline">
          <div v-for="(device, index) in activeDevices"
            :key="index"
            class="column is-narrow">
            <div v-if="device"
              class="card">
              <header class="card-header">
                <p class="card-header-title">
                  <span v-if="!device.isEdit"
                    class="is-uppercase">{{ device.deviceName }}</span>
                  <span v-else
                    style="width: 100%">
                    <input class="input is-uppercase"
                      :class="{ 'is-static': !device.isEdit, 'is-danger' : $v.devices.$each[index].deviceName.$error }"
                      :readonly="!device.isEdit"
                      v-model="device.deviceName"
                      placeholder="Device Name">
                    <span class="help is-danger"
                      v-if="$v.devices.$each[index].deviceName.$dirty && !$v.devices.$each[index].deviceName.required">Device Name is required.</span>
                  </span>
                </p>
                <div v-if="canManage"
                  class="buttons"
                  style="margin-right: 5px; margin-top: -5px">
                  <a class="has-text-primary is-outlined"
                    @click="toggleEdit(index, device)">
                    <span class="icon">
                      <i class="mdi mdi-24px"
                        :class="[device.isEdit ? 'mdi-content-save' : 'mdi-lead-pencil']"
                        aria-hidden="true" />
                    </span>
                  </a>
                  <a class="has-text-danger is-outlined"
                    @click="deleteDevice(index, device)">
                    <span class="icon">
                      <i class="mdi mdi-delete mdi-24px"
                        aria-hidden="true" />
                    </span>
                  </a>
                </div>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class="field">
                    <label class="label is-small">Device ID</label>
                    <div class="control">
                      <input class="input is-small deviceid-input-small is-uppercase"
                        :class="{ 'is-static': !device.isEdit, 'is-danger' : $v.devices.$each[index].deviceId.$error }"
                        :readonly="!device.isEdit"
                        v-model="device.deviceId"
                        placeholder="Device ID">
                      <span class="help is-danger"
                        v-if="$v.devices.$each[index].deviceId.$dirty && !$v.devices.$each[index].deviceId.required">Device ID is required.</span>
                    </div>
                  </div>
                  <div class="field">
                    <div class="field-body">
                      <div class="field">
                        <label class="label is-small">Device Information</label>
                        <div class="control">
                          <input class="input is-small"
                            :class="{ 'is-static': !device.isEdit, 'is-danger' : $v.devices.$each[index].deviceInformation.$error }"
                            :readonly="!device.isEdit"
                            v-model="device.deviceInformation"
                            placeholder="Device ID">
                          <span class="help is-danger"
                            v-if="$v.devices.$each[index].deviceInformation.$dirty && !$v.devices.$each[index].deviceInformation.required">Device Information is required.</span>
                        </div>
                      </div>
                      <div v-if="!device.isEdit"
                        class="field">
                        <label class="label is-small">Registered Date</label>
                        <div class="control">
                          <span class="is-size-7">{{ $filters.formatDateLocale(device.registeredDate, $userInfo.locale) }}</span>
                          <!-- <input class="input is-small"
                        :class="{ 'is-static': !device.isEdit }"
                        :readonly="!device.isEdit"
                        v-model="device.registeredDate"
                        placeholder="Device ID"> -->
                        </div>
                      </div>
                      <div v-else
                        class="field">
                        <label class="label is-small">Registered Date</label>
                        <v-date-picker v-model="device.selectedDate"
                          :masks="formats"
                          :attributes="attrs"
                          :locale="$userInfo.locale"
                          :disabled="!device.isEdit">
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="field">
                              <div class="control has-icons-left">
                                <input type="text"
                                  class="input is-small"
                                  placeholder="Registered Date"
                                  :value="inputValue"
                                  v-on="inputEvents">
                                <span class="icon is-small is-left">
                                  <i class="mdi mdi-calendar mdi-18px" />
                                </span>
                              </div>
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal v-if="device"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Device Registration</p>
      <p slot="text-content">
        Device
        <span class="has-text-primary has-text-weight-bold">{{ `${device.deviceName}` }}</span> will be de-registered. Continue?
        <!-- <span class="tag is-danger">In-Active</span> anyway? -->
      </p>
    </confirm-modal>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { AppHeaderButtonTypes } from '@/enums'
import { required } from 'vuelidate/lib/validators'
import ClientToolService from './ClientToolService'
import { ConfirmModal } from '@/components/BulmaModal'

export default {
  name: 'ClientTool',
  components: {
    AppDetailHeader,
    ConfirmModal
  },
  mixins: [DateTimeFiltersMixin],
  data: () => ({
    formats: {
      title: 'MMMM YYYY',
      weekdays: 'W',
      navMonths: 'MMM',
      input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
      data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
      dayPopover: 'L'
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          backgroundColor: '#ff8080'
        },
        popover: {
          label: 'Today'
        },
        dates: new Date()
      }
    ],
    devices: [],
    isConfirmModalActive: false,
    device: null,
    isLoading: false
  }),
  computed: {
    canManage() {
      return this.$userInfo.isSupportUser
    },
    activeDevices() {
      return this.devices.filter(d => d.active)
    }
  },
  created() {
    this.getClientToolDevices()
  },
  validations: {
    devices: {
      $each: {
        deviceId: {
          required
        },
        deviceName: {
          required
        },
        deviceInformation: {
          required
        },
        registeredDate: {
          required
        }
      }
    }
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async getClientToolDevices() {
      // this.devices.splice(this.devices.length, 1, {
      //   deviceName: 'TONY-PC',
      //   deviceId: 'EJPPG-VMDOZ-BAQYU-NKLGA-HKBSA-UFXCA-PRVUT-LJTGK-JIWVJ-OBQAT-QMNFN-SXTWA',
      //   deviceInformation: 'Windows 10 Pro',
      //   registeredDate: '2019-09-17T14:00:00Z',
      //   selectedDate: new Date('2019-09-17T14:00:00Z'),
      //   isEdit: false
      // })
      // this.devices.splice(this.devices.length, 1, {
      //   deviceName: 'TANYA-PC',
      //   deviceId: 'EJPPG-VMDOZ-BAQYU-NKLGA-HKBSA-UFXCA-PRVUT-LJTGK-JIWVJ-OBQAT-QMNFN-SXTWB',
      //   deviceInformation: 'Windows 10 Pro',
      //   registeredDate: '2019-09-19T14:00:00Z',
      //   selectedDate: new Date('2019-09-19T14:00:00Z'),
      //   isEdit: false
      // })
      // this.devices.splice(this.devices.length, 1, {
      //   deviceName: 'HAROLD-PC',
      //   deviceId: 'EJPPG-VMDOZ-BAQYU-NKLGA-HKBSA-UFXCA-PRVUT-LJTGK-JIWVJ-OBQAT-QMNFN-SXTWC',
      //   deviceInformation: 'Windows 10 Pro',
      //   registeredDate: '2019-09-18T14:00:00Z',
      //   selectedDate: new Date('2019-09-18T14:00:00Z'),
      //   isEdit: false
      // })
      this.isLoading = true
      this.devices = await ClientToolService.getDevices(this.$userInfo.companyId)
      console.log(this.devices)
      this.devices.forEach(d => d.selectedDate = new Date(d.registeredDate))
      this.isLoading = false
    },
    async toggleEdit(index, device) {
      device.isEdit = !device.isEdit
      if (!device.isEdit) {
        // Save device changes
        this.$v.devices.$touch()
        console.log('Saving', device)
        if (!device.deviceName || !device.deviceId || !device.deviceInformation) {
          this.$notification.openNotificationWithType('danger', 'Client Tool', 'Validation errors. Please fix before saving')
          device.isEdit = true
        } else {
          device.deviceId = device.deviceId.toUpperCase()
          device.deviceName = device.deviceName.toUpperCase()
          device.registeredDate = device.selectedDate.toISOString().split('.')[0] + 'Z'
          if (device.isNew) {
            const response = await ClientToolService.insertDevice(device)
            response.data.selectedDate = new Date(response.data.registeredDate)
            this.devices.splice(index, 1, response.data)
            console.log('insert', response)
            this.$notification.openNotificationWithType('success', 'Client Tool', 'New device registration added')
          } else {
            const response = await ClientToolService.saveDevice(device)
            this.$notification.openNotificationWithType('success', 'Client Tool', 'Device registration saved')
            // this.devices.splice(index, 1, response.data)
            console.log('insert', response)
          }
        }
      }
    },
    addNew() {
      const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
      const newDevice = {
        id: this.$guid.newGuid(),
        companyId: this.$userInfo.companyId,
        deviceId: '',
        deviceName: '',
        deviceInformation: '',
        registeredDate: utcDate.split('.')[0] + 'Z',
        active: true,
        selectedDate: new Date(utcDate),
        isEdit: true,
        isNew: true
      }
      this.devices.splice(this.devices.length, 1, newDevice)
    },
    deleteDevice(index, device) {
      this.device = device
      if (device.isNew) {
        this.devices.splice(index, 1)
      } else {
        this.isConfirmModalActive = true
      }
    },
    async deleteEntity(confirmDelete) {
      if (confirmDelete) {
        this.device.active = false
        const response = await ClientToolService.saveDevice(this.device)
        console.log('delete', response)
        const index = this.devices.findIndex(d => d.id === this.device.id)
        this.devices.splice(index, 1)
        this.device = null
        this.$notification.openNotificationWithType('success', 'Client Tool', 'Device has been de-registered')
      }
      this.device = null
    }
  }
}
</script>

<style lang="scss">
.deviceid-input {
  width: 45em;
}
.deviceid-input-small {
  width: 45em;
}
</style>